( function () {
	( function ( $ ) {
        
        //Add value to commaseperated list
        var obflexaddValue = function (list, value, separator) {
            separator = separator || ",";
            var values = list.toString().split(separator);
            for (var i = 0; i < values.length; i++) {
                if (values[i] === value) {
                    return list;
                }
            }
            values.push(value);
            return values.join(separator);
        };

        //Function for next step
        function apbFormNextStep() {
            var currentSetp = $('.apb-step.current');
            var currentStepContent = $('.apb-step-block.active');
            var goahead = true;
            currentStepContent.find('.apb-required').each(function () {
                $(this).parents('.apb-formfield-wrapper').removeClass('err');
                $(this).parents('.apb-formfield-wrapper').find('.apb_err_msg').remove();

                var filter = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
                if ($(this).val() === "") {
                    $(this).parents('.apb-formfield-wrapper').addClass('err');
                    $(this).parents('.apb-formfield-wrapper').append('<span class="apb_err_msg">Dit veld is verplicht</span>');
                    goahead = false;
                    return;
                }
                else {
                    if ($(this).hasClass('apb-email-field')) {
                        if (!filter.test($(this).val())) {
                            $(this).parents('.apb-formfield-wrapper').addClass('err');
                            $(this).parents('.apb-formfield-wrapper').append('<span class="apb_err_msg">Ongeldig e-mailadres</span>');
                            goahead = false;
                            return;
                        }
                    }
                    else {
                        $(this).parents('.apb-formfield-wrapper').removeClass('err');
                        $(this).parents('.apb-formfield-wrapper').find('.apb_err_msg').remove();
                        goahead = true;
                    }
                }
            });

            if (goahead) {
                currentSetp.removeClass('current').addClass('selected');
                currentSetp.parent().next().find('a').removeClass('disabled').addClass('current');
                currentStepContent.removeClass('active');
                currentStepContent.next().addClass('active');
                if(currentStepContent.next().attr('id') === 'apb-step-personalinfo'){
                    /*if(apbAjax.captchver==='v3'){
                        //obspgetRecaptcha();
                    }*/
                }
                $('.modal').scrollTop(0);
            }
        }

        //function to load reparaties data from custom APIs
        function apb_loadReparatie(currentId, FirstLoad){    
            $.ajax({
                type: 'GET',
                url: obflexObj.restURL + 'devices/v1/model/' + currentId,
                beforeSend: function (xhr){
                    $('.obflex-loader-overlay').addClass('show');
                    xhr.setRequestHeader('X-WP-Nonce', obflexObj.restNonce);
                },                
                success: function (response){
                    $('.obflex-loader-overlay').removeClass('show');
                    $('.apb-reparaties-options').html('');
                    $.each(response, function (i, item) {
                        var reparatieitem = '<a href="#" class="reparatie-item" data-id="' + item.ID + '" data-name="' + item.title + '" data-price-currency="' + item.price_cur + '" data-price="' + item.price + '"><span>' + item.title + '</span><span>' + item.price_cur + '</span></a>';
                        $('.apb-appointment-form').find('.apb-reparaties-options').append(reparatieitem);
                        
                        var reparatieitem2 = '<a href="#" class="reparatie-item is-filter" data-id="' + item.ID + '" data-name="' + item.title + '" data-price-currency="' + item.price_cur + '" data-price="' + item.price + '"><span>' + item.title + '</span><span>' + item.price_cur + '</span></a>';
                        $('.filter-box-content').find('.apb-reparaties-options').append(reparatieitem2);
                    });
                }
            });
        }
        //function to update selection in right side
        function apbUpdateCart(is_filter){
            $('.apbcart-reparaties-list .items').html('');
            if ($('#apb-appointment-form .apb-reparaties-options .reparatie-item.active').length) {
                var montagekosten = 0;
                var total = 0;
                var totalreparaties = $('#apb-appointment-form .apb-reparaties-options').find('.reparatie-item.active').length;
                var selectedReparaties = "";
                $('#apb-appointment-form .apb-reparaties-options').find('.reparatie-item.active').each(function () {
                    var rid = $(this).data('id');
                    var rname = $(this).data('name');
                    var price = $(this).data('price')==="Bellen voor prijs" ? '0' : $(this).data('price');
                    var price_curr = $(this).data('price-currency');
                    if (selectedReparaties === "") {
                        selectedReparaties = rid.toString();
                    }
                    else {
                        selectedReparaties = obflexaddValue(selectedReparaties, rid);
                    }
                    var cart_rhtml = '<div class="apbcart-reparatie-item" data-rid="' + rid + '"><span class="item-name">' + rname + '</span><span class="item-price">' + price_curr + '</span></div>';
                    $('.apbcart-reparaties-list .items').append(cart_rhtml);
                    /*var mk = $(this).data('mk');
                    if (parseInt(mk) === 1) {
                        totalreparaties--;
                    }*/
                    total = total + parseFloat(price);
                });
                if(!is_filter){
                    $('#apb-appointment-form input[name="apbfield[reparaties]"]').val(selectedReparaties);
                }
                else{
                    $('input[name="apbfield[reparaties]"]').val(selectedReparaties);
                }
                /*if (totalreparaties > 1) {
                    montagekosten = parseInt(obsapbAjax.montagekosten) * (totalreparaties - 1);
                }

                var montagekostenStr = currency(montagekosten, { symbol: '€', decimal: ',', separator: '.' }).format();
                montagekostenStr = montagekostenStr.replace(",00", "");
                */

                var totalStr = currency(total, { symbol: '€', decimal: ',', separator: '.' }).format();
                totalStr = totalStr.replace(",00", ",-");

                var grandtotal = total - montagekosten;
                var grandtotalStr = currency(grandtotal, { symbol: '€', decimal: ',', separator: '.' }).format();
                grandtotalStr = grandtotalStr.replace(",00", ",-");

                if(!is_filter){
                    $('#apb-appointment-form .apbcart-totalcost-item').removeClass('hide');
                    if (montagekosten > 0) {
                        $('#apb-appointment-form .apbcart-totaldiscount-item').removeClass('hide');
                    }
                    else{
                        $('#apb-appointment-form .apbcart-totaldiscount-item').addClass('hide');
                    }
                    $('#apb-appointment-form .apbcart-nettotal-item').removeClass('hide');

                    $('#apb-appointment-form .apbcart-totalcost-item').find('.item-price').html(totalStr);
                    //$('#apb-appointment-form .apbcart-totaldiscount-item').find('.item-price').html(montagekostenStr);
                    $('#apb-appointment-form .apbcart-nettotal-item').find('.item-price').html(grandtotalStr);  
                    $('#apb-appointment-form .apbcart-reparaties-list').removeClass('hide'); 
                }
                else{
                    $('.apbcart-totalcost-item').removeClass('hide');
                    if (montagekosten > 0) {
                        $('.apbcart-totaldiscount-item').removeClass('hide');
                    }
                    else{
                        $('.apbcart-totaldiscount-item').addClass('hide');
                    }
                    $('.apbcart-nettotal-item').removeClass('hide');

                    $('.apbcart-totalcost-item').find('.item-price').html(totalStr);
                    //$('#apb-appointment-form .apbcart-totaldiscount-item').find('.item-price').html(montagekostenStr);
                    $('.apbcart-nettotal-item').find('.item-price').html(grandtotalStr);  
                    $('.apbcart-reparaties-list').removeClass('hide');
                }
            }
            else {
                $('input[name="apbfield[reparaties]"]').val('');
                $('.apbcart-totalcost-item').addClass('hide');
                //$('.apbcart-totaldiscount-item').addClass('hide');
                $('.apbcart-nettotal-item').addClass('hide');
                $('.apbcart-reparaties-list').addClass('hide');
                $('.apbcart-reparaties-list .items').html('');
            }
            return;
        }
        
        //function to load data from custom APIs
        function apb_loadModels(currentId, FirstLoad){  
            $.ajax({
                type: 'GET',
                url: obflexObj.restURL + 'devices/v1/brands?cid='+currentId,
                beforeSend: function (xhr){
                    $('.obflex-loader-overlay').addClass('show');
                    xhr.setRequestHeader('X-WP-Nonce', obflexObj.restNonce);
                },                
                success: function (response){
                    $('.obflex-loader-overlay').removeClass('show');
                    $('#apb-appointment-form')[0].reset();
                    $('.apb-brand-options').html('');
                    $('.apb-model-options').html('');
                    var curentTypeID = '';
                    $.each(response, function (i, item) {
                        var bitemclasses = item.types.length > 0 ? 'apb-filter-option apb-filter-branditem has-types' : 'apb-filter-option apb-filter-branditem';
                        var branditem = '<a href="#" class="'+bitemclasses+'" data-type="brand" data-text="' + item.post_title + '" data-id="' + item.ID + '" rel="nofollow">' + item.post_title + '</a>';
                        $('.apb-brand-options').append(branditem);
                        if(item.types!==""){
                            $.each(item.types, function (k, t) {
                                var titemclasses = 'apb-filter-option apb-filter-typeitem hide';

                                var typeitem = '<a href="#" class="'+titemclasses+'" data-type="type" data-brand="' + item.ID + '" data-text="' + t.post_title + '" data-id="' + t.ID + '" rel="nofollow">' + t.post_title + '</a>';
                                $('.apb-types-options').append(typeitem);
                                if(t.is_current){
                                    curentTypeID = t.ID;
                                }
                            });
                        }
                        $.each(item.models, function (j, m) {
                            var mitemclasses = 'apb-filter-option apb-filter-modelitem hide';

                            var modelitem = '<a href="#" class="'+mitemclasses+'" data-type="model" data-brand="' + m.brand_id + '" data-brandname="' + m.brand_title + '" data-typeid="' + m.type_id + '" data-typename="' + m.type_title + '" data-text="' + m.brand_title + ' ' + m.post_title + '" data-id="' + m.ID + '" rel="nofollow">' + m.post_title + '</a>';
                            $('.apb-model-options').append(modelitem);
                        });

                        if(item.is_current === 'active'){
                            $('.apb-filter-branditem[data-id="'+item.ID+'"]').click();
                            if(curentTypeID!==""){
                                $('.apb-filter-typeitem[data-id="'+curentTypeID+'"]').click();
                            }                            
                            $('.apb-filter-modelitem[data-id="'+currentId+'"]').click();
                            apbFormNextStep();
                        }
                    });
                }
            });
        }

        function initanbDatePickers($selector, closedays, openweekoffdays, weekoffdays) {
            if (!$selector.length) {
                return;
            }
            var natDays = closedays;
            var fweekends = weekoffdays.toString().split(',');
    
            $selector.datepicker("destroy");
            $selector.datepicker({
                minDate: 0,
                dateFormat: 'dd-mm-yy',
                altFormat: 'dd MM yy',
                altField: '#apb-altdate',
                beforeShowDay: function (date) {
                    if (natDays.length === 0) {
                    }
                    else {
                        for (var i = 0; i < natDays.length; i++) {
                            var tmparr = natDays[i].toString().split(',');
                            if (date.getMonth() === parseInt(tmparr[0]) - 1 && date.getDate() === parseInt(tmparr[1]) && date.getFullYear() === parseInt(tmparr[2])) {
                                return [false, tmparr[3] + '_day'];
                            }
                        }
                    }
                    var day = date.getDay();
                    for (var k = 0; k < fweekends.length; k++) {
                        for (var j = 0; j < openweekoffdays.length; j++) {
                            var tmparr3 = openweekoffdays[j].toString().split(',');
                            if (date.getMonth() === parseInt(tmparr3[0]) - 1 && date.getDate() === parseInt(tmparr3[1]) && date.getFullYear() === parseInt(tmparr3[2])) {
                                return [true, tmparr3[3] + '_day'];
                            }
                        }
                        if (weekoffdays !== "" && day === fweekends[k]) {
                            return [false, ''];
                        }
    
                    }
                    return [true, ''];
                },
                onSelect: function (dateText, inst) {
                    $('.apbcart-appointment').addClass('hide');
                    $('.apbcart-appointment .items').html('');
                    $(this).parents('.apb-formfield-wrapper').find('.apb_err_msg').remove();
                    $.ajax({
                        type: 'GET',
                        url: obflexObj.restURL + 'appointments/v1/timeslots/' + dateText,
                        beforeSend: function (xhr){
                            xhr.setRequestHeader('X-WP-Nonce', obflexObj.restNonce);
                        },                
                        success: function (response){
                            $('.apb-timeslotslist .timeslots').html('');
                            $('.apb-timeslotslist').prev().val('');
                            $.each(response, function (i, item) {
                                if (item.status === 'avail') {
                                    var timeslotitem = '<li class="' + item.status + '" data-time="' + item.slotstart + '-' + item.slotend + '" data-stime="' + item.slotstart +'">' + item.slotstart + ' - ' + item.slotend + '</li>';
                                    $('.apb-timeslotslist .timeslots').append(timeslotitem);
                                }
                            });
                        }
                    });                    
                }
            });
        }
        //Function for reset steps
        function apbFormResetStep() {
            $('.apb-step').removeClass('current').removeClass('selected').addClass('disabled');
            $('.apb-step-block.active').removeClass('active');
            $('.apb-steps-nav li:first-child a').removeClass('disabled').addClass('current');
            $('.apb-step-block:first-child').addClass('active');
        }
        //Event listner for button class to open popup
        $(document).on('click', '.apb-modal-button', function (e) {
            e.preventDefault();
            $('#apb-bookingmodal').modal({ 
                escapeClose: true, 
                clickClose: false, 
                showClose: true,
                fadeDuration: 100,
            });
        });

        //Event listner for popup go to next step button
        $(document).on('click', '.next-step', function (e) {
            e.preventDefault();
            apbFormNextStep();
        });

        //Event to click on filter select dropdown div
        $(document).on('click', '.apb-filter-selectbutton', function (e) {
            e.preventDefault();
            e.stopPropagation();
            if (!$(this).hasClass('disable')) {
                if ($(this).hasClass('active')) {
                    $(this).removeClass('active');
                    $(this).next('.apb-filter-options').removeClass('active');
                }
                else {
                    $('.apb-filter-options.active').removeClass('active');
                    $(this).addClass('active');
                    $(this).next('.apb-filter-options').addClass('active');
                }
            }
        });

        //Event to click on filter option to select
        $(document).on('click', '.apb-filter-option', function (e) {
            e.preventDefault();
            e.stopPropagation();
            var text = $(this).text();

            var pid = $(this).data('id');
            $(this).parent().find('.apb-filter-option.selected').removeClass('selected');
            $(this).parents('.apb-filter-field').find('input[type="hidden"]').val(pid);

            $(this).addClass('selected');
            $(this).parent().prev('.apb-filter-selectbutton').find('.text').html(text);
            $(this).parent().prev('.apb-filter-selectbutton').removeClass('active');
            $(this).parent().removeClass('active');

            $('.apb-filter-field').find('input[name="apbfield[reparaties]"]').val('');
            $('.fsel_reparaties').find('.text').html('Kies reparatie');
            $('.apb-reparaties-options').html('');
            $('.apbcart-reparaties-list .items').html('');
            $('.apbcart-reparaties-list').addClass('hide');
            $('.apbcart-totalcost-item').addClass('hide');
            $('.apbcart-nettotal-item').addClass('hide');
            $('.apbcart-totalcost-item').find('.item-price').html('0');
            $('.apbcart-nettotal-item').find('.item-price').html('0');

            var nextFormField = $(this).parents('.apb-formfield-wrapper').next();
            if($(this).hasClass('apb-filter-branditem')){                
                if($(this).hasClass('has-types')){
                    nextFormField.removeClass('hide');
                    $('.apb-appointment-form').find('.apb-formfield-wrapper-types').removeClass('hide');

                    nextFormField.find('.apb-filter-option').addClass('hide');                    
                    nextFormField.find('.apb-filter-option').removeClass('selected');
                    nextFormField.find('input[type="hidden"]').val('');
                    nextFormField.find('input[type="hidden"]').addClass('apb-required');
                    nextFormField.find('.apb-filter-selectbutton').find('.text').html('Selecteer type');

                    var nextFormField2 = nextFormField.next();
                    nextFormField2.find('.apb-filter-option').addClass('hide');
                    nextFormField2.find('.apb-filter-option').removeClass('selected');
                    nextFormField2.find('input[type="hidden"]').val('');
                    nextFormField2.find('.apb-filter-selectbutton').find('.text').html('Selecteer model');
                    
                    nextFormField.find('.apb-filter-option').each(function(e){
                        if($(this).data('brand') === pid){
                            $(this).removeClass('hide');
                        }
                    });
                }
                else{
                    var tmpnextFormField = $(this).parents('.apb-formfield-wrapper').next();
                    tmpnextFormField.addClass('hide');
                    $('.apb-appointment-form').find('.apb-formfield-wrapper-types').addClass('hide');
                    tmpnextFormField.find('input[type="hidden"]').val('');
                    tmpnextFormField.find('input[type="hidden"]').removeClass('apb-required');
                    tmpnextFormField.find('.apb-filter-selectbutton').find('.text').html('Selecteer type');

                    nextFormField = tmpnextFormField.next();
                    nextFormField.find('.apb-filter-option').addClass('hide');
                    nextFormField.find('.apb-filter-option').removeClass('selected');
                    nextFormField.find('input[type="hidden"]').val('');
                    nextFormField.find('.apb-filter-selectbutton').find('.text').html('Selecteer model');
                    
                    nextFormField.find('.apb-filter-option').each(function(e){
                        if($(this).data('brand') === pid){
                            $(this).removeClass('hide');
                        }
                    });
                }
            }
            else if($(this).hasClass('apb-filter-typeitem')){                
                nextFormField.find('.apb-filter-option').addClass('hide');
                nextFormField.find('.apb-filter-option').removeClass('selected');
                nextFormField.find('input[type="hidden"]').val('');
                nextFormField.find('.apb-filter-selectbutton').find('.text').html('Selecteer model');
                
                nextFormField.find('.apb-filter-option').each(function(e){
                    if($(this).data('typeid') === pid){
                        $(this).removeClass('hide');
                    }
                });
            }
            else if($(this).hasClass('apb-filter-modelitem')){
                var modelText = $(this).data('text');                
                $('.apbcart-model-info').find('.items').html(modelText);
                $('.apbcart-model-info').removeClass('hide');
                $('.apb-cart-info').addClass('hide');
                apb_loadReparatie(pid, false);
            }
        });

        //Event to click on outside of filter select and close filter
        $(window).click(function () {
            $('.apb-filter-selectbutton.active').removeClass('active');
            $('.apb-filter-options.active').removeClass('active');
            $('.apb-timeslotslist').removeClass('active');
        });

        //Reparatie item click
        $(document).on('click', '.reparaties-list .reparatie-item, .apb-reparaties-options .reparatie-item', function (e) {
            e.preventDefault();
            e.stopPropagation();
            var is_filter = $(this).parents('.filter-box').length ? true : false;
            var is_model_reparatie = $(this).hasClass('is-model-reparatie') ? true : false;
            var dataid = $(this).data('id');
            if ($(this).hasClass('active')) {
                $(this).removeClass('active'); 
                if(!is_filter){
                    $('#apb-appointment-form .apb-reparaties-options').find('.reparatie-item[data-id="' + dataid + '"]').removeClass('active');
                }
                else{
                    $('.apb-reparaties-options').find('.reparatie-item[data-id="' + dataid + '"]').removeClass('active');
                }
                $('.reparaties-list').find('.reparatie-item[data-id="' + dataid + '"]').removeClass('active');
            }
            else {
                $(this).addClass('active');
                if(!is_filter){
                    $('#apb-appointment-form .apb-reparaties-options').find('.reparatie-item[data-id="' + dataid + '"]').addClass('active'); 
                }
                else{
                    $('.apb-reparaties-options').find('.reparatie-item[data-id="' + dataid + '"]').addClass('active'); 
                }
                $('.reparaties-list').find('.reparatie-item[data-id="' + dataid + '"]').addClass('active');
            }

            var selectedLength = $('#apb-appointment-form').find('.reparatie-item.active').length;
            if(selectedLength === 1){
                $('#apb-appointment-form .fsel_reparaties').find('.text').html($('#apb-appointment-form').find('.reparatie-item.active').data('name'));
            }
            else if (selectedLength > 0) {
                $('#apb-appointment-form .fsel_reparaties').find('.text').html(selectedLength + ' Geselecteerd');
            }
            else {
                $('#apb-appointment-form .fsel_reparaties').find('.text').html('Maak een keuze');
            }
            if(is_filter){
                var selectedLength2 = $('.filter-box').find('.reparatie-item.active').length;
                if(selectedLength2 === 1){
                    $('.filter-box .fsel_reparaties').find('.text').html($('.filter-box').find('.reparatie-item.active').data('name'));
                }
                else if (selectedLength2 > 0) {
                    $('.filter-box .fsel_reparaties').find('.text').html(selectedLength + ' Geselecteerd');
                }
                else {
                    $('.filter-box .fsel_reparaties').find('.text').html('Maak een keuze');
                }
            }

            $(this).parent().prev('.apb-filter-selectbutton').removeClass('active');
            $(this).parent().removeClass('active');
            
            apbUpdateCart(is_filter);

            if(is_model_reparatie){
                apbFormResetStep();
                apbFormNextStep();
                $('#apb-bookingmodal').modal({ 
                    escapeClose: true, 
                    clickClose: false, 
                    showClose: true,
                    fadeDuration: 100,
                });
            }            
        });

        //function to open popup on filter button click
        $(document).on('click','.apb-filter-button',function(e){
            e.preventDefault();
            var brand = $('.apb-filter-field').find('input[name="apbfield[brand]"]').val();
            var type = $('.apb-filter-field').find('input[name="apbfield[type]"]').val();
            var model = $('.apb-filter-field').find('input[name="apbfield[model]"]').val();
            var reparaties = $('.apb-filter-field').find('input[name="apbfield[reparaties]"]').val();
            $('.filter-errro').remove();
            
            if( brand !=='' && model !== '' && reparaties!==''){
                $('#apb-appointment-form').find('input[name="apbfield[brand]"]').val(brand);
                $('#apb-appointment-form').find('input[name="apbfield[model]"]').val(model);
                $('#apb-appointment-form').find('input[name="apbfield[type]"]').val(type);

                var mbtext = $('#apb-appointment-form').find('.apb-filter-branditem[data-id="'+brand+'"]').addClass('selected').data('text');
                var mttext = $('#apb-appointment-form').find('.apb-filter-typeitem[data-id="'+type+'"]').addClass('selected').data('text');
                var mmtext = $('#apb-appointment-form').find('.apb-filter-modelitem[data-id="'+model+'"]').addClass('selected').html();
                
                $('#apb-appointment-form').find('.apb-filter-selectbutton.fsel_brand').find('.text').html(mbtext);
                $('#apb-appointment-form').find('.apb-filter-selectbutton.fsel_type').find('.text').html(mttext);
                $('#apb-appointment-form').find('.apb-filter-selectbutton.fsel_model').find('.text').html(mmtext);
                
                
                if(type!==''){
                    $('#apb-appointment-form').find('.apb-formfield-wrapper-types').removeClass('hide');
                    $('#apb-appointment-form').find('.apb-filter-typeitem[data-brand="'+brand+'"]').removeClass('hide');
                    $('#apb-appointment-form').find('.apb-filter-modelitem[data-typeid="'+type+'"]').removeClass('hide');
                }
                else{
                    $('#apb-appointment-form').find('.apb-formfield-wrapper-types').addClass('hide');
                    $('#apb-appointment-form').find('.apb-filter-typeitem').addClass('hide');
                    $('#apb-appointment-form').find('.apb-filter-modelitem[data-brand="'+brand+'"]').removeClass('hide');
                }
                apbFormResetStep();
                apbFormNextStep();
                apbFormNextStep();
                $('#apb-bookingmodal').modal({ 
                    escapeClose: true, 
                    clickClose: false, 
                    showClose: true,
                    fadeDuration: 100,
                });
            }
            else{
                $(this).parent().prepend('<div class="filter-errro">Vul alle velden in</div>');
            }
        });

        //Click event for steps item
        $(document).on('click', '.apb-step.selected', function (e) {
            e.preventDefault();
            $(this).parent().nextAll().find('.selected').removeClass('selected');
            $(this).parent().nextAll().find('.current').removeClass('current');
            $(this).removeClass('selected');
            $(this).addClass('current');
            var cindex = $(this).parent().index();
            $('.apb-step-block.active').removeClass('active');
            $('.apb-step-block').eq(cindex).addClass('active');
        });

        //Event for time slot field
        $(document).on('focus', '.apb-time-field', function (e) {
            e.stopPropagation();
            $(this).next().addClass('active');
        });
        $(document).on('click', '.apb-time-field', function (e) {
            e.stopPropagation();
        });

        //Event for timeslot selection
        $(document).on('click', '.timeslots li.avail', function (e) {
            e.stopPropagation();
            $(this).parents('.apb-formfield-wrapper').find('.apb_err_msg').remove();
            var altdate = $('#apb-altdate').val();
            $('.timeslots li.selected').removeClass('selected');
            $(this).addClass('selected');
            $('.apb-timeslotslist').removeClass('active');
            $(this).parents('.apb-timeslotslist').prev().val($(this).data('time'));
            $('.apbcart-appointment .items').append('<div>'+altdate+'</div>');
            $('.apbcart-appointment .items').append('<div>'+$(this).data('stime')+'</div>');
            $('.apbcart-appointment').removeClass('hide');
        });

        //Form submit action
        $('#apb-appointment-form').submit(function (e) {
            e.preventDefault();
            var tobj = $(this);
            var filter = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
            var formhaserror = false;
            $(".apb-step-block.active .apb-required").each(function (index, element) {
                $(this).parents('.apb-formfield-wrapper').removeClass('err');
                $(this).parents('.apb-formfield-wrapper').find('.apb_err_msg').remove();
                if ($(this).val() === "") {
                    $(this).parents('.apb-formfield-wrapper').addClass('err');
                    $(this).parents('.apb-formfield-wrapper').append('<span class="apb_err_msg">Dit veld is verplicht</span>');
                    formhaserror = true;
                }
                else {
                    if ($(this).hasClass('apb-email-field')) {
                        if (!filter.test($(this).val())) {
                            $(this).parents('.apb-formfield-wrapper').addClass('err');
                            $(this).parents('.apb-formfield-wrapper').append('<span class="apb_err_msg">Ongeldig e-mailadres</span>');
                            formhaserror = true;
                        }
                    }
                }
            });
            if (!formhaserror) {
                $('#apb-bookingmodal').find('.obflex-loader-overlay').addClass('show');
                $('.apb-step-block.active').find('.apb-response').removeClass('error').html('');
                $('.apb-step-block.active').find('.apb-response').removeClass('success');
                $.ajax({
                    type: "POST",
                    cache: false,
                    url: obflexObj.ajaxUrl,
                    data: { nonce: obflexObj.ajaxNonce, action: 'apb-submit', formdata: $('#apb-appointment-form').serialize() },
                    dataType: 'json',
                    success: function (data) {
                        if(data.hascaptcha==='yes'){
                            grecaptcha.reset();
                        }
                        /*if(obflexObj.captchver==='v3'){
                            obspgetRecaptcha();
                        }*/
                        if (data.error !== "") {
                            $('.apb-step-block.active').find('.apb-response').addClass('error').html(data.error);
                            $('#apb-bookingmodal').find('.obflex-loader-overlay').removeClass('show');
                        }
                        else {
                            $('#apb-appointment-form')[0].reset();
                            $(".apb-field").each(function (index, element) {
                                $(this).val('');
                            });

                            if (data.surl !== "") {
                                window.setTimeout(function () {
                                    $('#apb-bookingmodal').find('.obflex-loader-overlay').removeClass('show');
                                    window.location.href = data.surl;
                                }, 1000);
                            }
                            else {
                                $('.apb-step-block.active').find('.apb-response').addClass('success').html(data.success);
                                $('#apb-bookingmodal').find('.obflex-loader-overlay').removeClass('show');
                            }

                        }
                    }
                });
            }
        });
        apb_loadModels($('input[name="apbform-pgid"]').val(),true);
        initanbDatePickers($('.apb-date-field'), obflexObj.closedays, obflexObj.openweekoffdays, obflexObj.weekoffdays);

    } )( jQuery );    
} )();