( function () {
	( function ( $ ) {
        $(document).on('click','.footer-widget h5',function() {
            if($(this).parent().find('> div').hasClass('expand')){
                $(this).parent().find('> div').removeClass("expand");
            }
            else{
                $('.footer-widget > div.expand').removeClass('expand');
                $(this).parent().find('> div').addClass("expand");
            }            
        });
        $(document).on('click', '#footer-widget-3 a', function (e) {
            e.preventDefault();
            var modellist = $(this).attr('href');
            $(modellist).modal({ 
                escapeClose: true, 
                clickClose: true, 
                showClose: true,
                fadeDuration: 100,
            });
        });
} )( jQuery );
} )();