/* ==========================================================================
  main.js
========================================================================== */

'use strict';
/* global fitvids, cssVars */

/**
 * Init responsive videos
 */
fitvids();

/**
 * Init CSS vars ponyfill
 */
cssVars({});

( function () {
	( function ( $ ) {
    $(document).on('click','.goto-section',function(e){
      e.preventDefault();
      var section_id=$(this).attr('href');      
      $('html, body').animate({
        scrollTop: $(section_id).offset().top
    }, 600);
    });
  } )( jQuery );    
} )();