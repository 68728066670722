/* Reviews carusel */
( function () {
	( function ( $ ) {
        $('.review__wrapper').slick({
            dots: false,
            infinite: false,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            rows:0,
            prevArrow: $('.section-reviews .prev'),
            nextArrow: $('.section-reviews .next'),
        });

        $(document).on('click','.toggle-reviews',function(e){
            e.preventDefault();
            $(this).parent().next().removeClass('hide');
            $(this).parent().addClass('hide');
            $(this).remove();
        });
	} )( jQuery );
} )();
