/**
 * File: mobile-menu.js
 *
 * Help deal with the off-canvas mobile menu.
 */
/**
 * Off canvas functions.
 *
 * @author Onlinebrothers
 * @since May 05, 2022
 */
function obflexOffCanvas() {
	const offCanvasScreen = document.querySelector( '.off-canvas-screen' );
	if ( ! offCanvasScreen ) {
		return;
	}

	const mobileMenuContainer = document.querySelector( '.offcanvas' ),
    mobileMenuTrigger = document.querySelector( '.mobile-menu-trigger' ),
    mobileMenuCloseTrigger = document.querySelector( '.mobile-menu-closetrigger' ),
    subMenuParentItem = document.querySelectorAll('.offcanvas-navigation__items li.menu-item-has-children');


	mobileMenuTrigger.addEventListener( 'click', toggleOffCanvas );
	offCanvasScreen.addEventListener( 'click', closeOffCanvas );
    mobileMenuCloseTrigger.addEventListener( 'click', closeOffCanvas );
	document.body.addEventListener( 'keydown', closeOnEscape );

    subMenuParentItem.forEach( ( subMenuParent ) => {
		const submenuItem = subMenuParent.querySelector( '.menu-item-has-children >a' );
		submenuItem.addEventListener( 'click', toggleSubmenu );

		const arrowItem = subMenuParent.querySelector( '.arrow' );
		arrowItem.addEventListener( 'click', toggleSubmenu );
	} );

	/**
	 * Close everything when we hit the escape key.
	 *
	 * @author Onlinebrothers
     * @since May 05, 2022
	 * @param {Object} event The event trigger.
	 */
	function closeOnEscape( event ) {
		if ( 27 === event.keyCode ) {
			closeOffCanvas();
		}
	}

	/**
	 * Handle closing the off-canvas overlay.
	 *
	 * @author Onlinebrothers
     * @since May 05, 2022
	 */
	function closeOffCanvas() {
		mobileMenuContainer.classList.remove( 'is-visible' );
		mobileMenuTrigger.classList.remove( 'is-visible' );
		offCanvasScreen.classList.remove( 'is-visible' );

		mobileMenuContainer.setAttribute( 'aria-hidden', true );
		mobileMenuTrigger.setAttribute( 'aria-expanded', false );
	}

	/**
	 * Toggle the display of the off-canvas overlay.
	 *
	 * @author Onlinebrothers
     * @since May 05, 2022
	 */
	function toggleOffCanvas() {
		if ( 'true' === mobileMenuTrigger.getAttribute( 'aria-expanded' ) ) {
			closeOffCanvas();
		} else {
			openOffCanvas();
		}
	}

	/**
	 * Handle opening the off-canvas overlay.
	 *
	 * @author Onlinebrothers
     * @since May 05, 2022
	 */
	function openOffCanvas() {
		mobileMenuContainer.classList.add( 'is-visible' );
		mobileMenuTrigger.classList.add( 'is-visible' );
		offCanvasScreen.classList.add( 'is-visible' );

		mobileMenuContainer.setAttribute( 'aria-hidden', false );
		mobileMenuTrigger.setAttribute( 'aria-expanded', true );
	}

    /**
	 * Open/Close a submenu.
	 *
	 * @author Onlinebrothers
     * @since May 05, 2022
	 * @param {Object} event The triggered event.
	 */
	function toggleSubmenu( event ) {
		event.preventDefault();

		const targetElement = event.target,
			targetParent = targetElement.parentNode,
			subMenu = targetParent.querySelector( 'ul.sub-menu' );

		closeAllSubmenus( targetParent );
		maybeOpenSubmenu( targetParent, subMenu );
	}

    /**
	 * Open a submenu.
	 *
	 * @author Onlinebrothers
     * @since May 05, 2022
	 * @param {Object} parent  The parent menu.
	 * @param {Object} subMenu The submenu.
	 */
	function maybeOpenSubmenu( parent, subMenu ) {
		if ( parent.classList.contains( 'is-visible' ) ) {
			closeSubmenu( parent, subMenu );
			return;
		}

		// Expand the list menu item, and set the corresponding button aria to true.
		parent.classList.add( 'is-visible' );
		parent
			.querySelector( '.arrow' )
			.setAttribute( 'aria-expanded', true );

		// Slide the menu in.
		subMenu.classList.add( 'is-visible');
	}

	/**
	 * Close a submenu.
	 *
	 * @author Onlinebrothers
     * @since May 05, 2022
	 * @param {Object} parent  The parent item.
	 * @param {Object} subMenu The submenu.
	 */
	function closeSubmenu( parent, subMenu ) {
		parent.classList.remove( 'is-visible' );
		parent
			.querySelector( '.arrow' )
			.setAttribute( 'aria-expanded', false );
		subMenu.classList.remove( 'is-visible');
	}

    /**
	 * Close all open submenus on the same
	 * level/hierarchys the menu we're trying
	 * to open.
	 *
	 * @author Onlinebrothers
     * @since May 05, 2022
	 * @param {Object} targetParent The target parent item.
	 */
	function closeAllSubmenus( targetParent ) {
		const submenuSiblings = getSiblings( targetParent );

		submenuSiblings.forEach( ( sibling ) => {
			sibling.classList.remove( 'is-visible' );

			if ( sibling.querySelector( '.arrow' ) ) {
				sibling
					.querySelector( '.arrow' )
					.setAttribute( 'aria-expanded', false );
			}

			if ( sibling.querySelector( '.sub-menu' ) ) {
				sibling
					.querySelector( '.sub-menu' )
					.classList.remove(
						'is-visible'
					);
			}
		} );
	}

	/**
	 * Find siblings of an item.
	 *
	 * @author Onlinebrothers
     * @since May 05, 2022
	 * @param {Object} element The element being opened.
	 * @return {Array} List of siblings.
	 */
	const getSiblings = function ( element ) {
		const siblings = [];
		let sibling = element.parentNode.firstChild;

		while ( sibling ) {
			if ( 1 === sibling.nodeType && sibling !== element ) {
				siblings.push( sibling );
			}

			sibling = sibling.nextSibling;
		}

		return siblings;
	};
}

// Make sure everything is loaded first.
if (
	( 'complete' === document.readyState ||
		'loading' !== document.readyState ) &&
	! document.documentElement.doScroll
) {
	obflexOffCanvas();
} else {
	document.addEventListener( 'DOMContentLoaded', obflexOffCanvas );
}

// ( function () {
// 	( function ( $ ) {
// 		if($(window).width() > 1069){			
// 			var nicelist = $("ul.submenu").niceScroll({
// 				cursorcolor: "#393939",
// 				cursorwidth: "3px", 
// 				cursorborder: "none",
// 				cursorborderradius: "3px",
// 				autohidemode: false,
// 				background:"#fff",
// 				fixed:true
// 			});
// 			$("ul > li > ul").on("shown.bs.dropdown",function(e){
// 				nicelist.show().resize();
// 			});

// 			$("ul > li > ul").on("hide.bs.dropdown",function(e){
// 				nicelist.hide();
// 			});
// 		}

//     } )( jQuery );    
// } )();