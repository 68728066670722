( function () {
	( function ( $ ) {
        $(document).on('click', '.brand-cta a', function (e) {
            e.preventDefault();
            var modellist = $(this).attr('href');
            $(modellist).modal({ 
                escapeClose: true, 
                clickClose: true, 
                showClose: true,
                fadeDuration: 100,
            });
        });
    } )( jQuery );    
} )();