( function () {
	( function ( $ ) {
        $(document).on('click','.scrollbottom-cta',function(e) {
            e.preventDefault();
            var thishref = $(this).attr('href');
            console.log(thishref);
            $('html, body').animate({                
                scrollTop: $(thishref).offset().top
            }, 1500);
        });
    } )( jQuery );    
} )();