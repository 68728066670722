const siteheader = document.querySelector(".site-header");

const scrollUp = "scroll-up";

const scrollDown = "scroll-down";

let lastScroll = 0;

 

window.addEventListener("scroll", () => {
	const currentScroll = window.pageYOffset;
	if (currentScroll <= siteheader.offsetHeight) {
		siteheader.classList.remove(scrollUp);
		return;
	}
	if (currentScroll > lastScroll && !siteheader.classList.contains(scrollDown)) {
		// down
		siteheader.classList.remove(scrollUp);
		siteheader.classList.add(scrollDown);
	} else if ( currentScroll < lastScroll && siteheader.classList.contains(scrollDown)	) {
		// up
		siteheader.classList.remove(scrollDown);
		siteheader.classList.add(scrollUp);
	}
	lastScroll = currentScroll;
});

/*const siteheader = document.querySelector(".site-header");
const siteheaderTop = siteheader.offsetTop;

window.addEventListener("scroll", () => {
	if (window.scrollY >= siteheaderTop + 64) {
		siteheader.classList.add('fixed-header');
	} else {
		siteheader.classList.remove('fixed-header');
	}
});*/

( function () {
	( function ( $ ) {
        $(document).on('click', '.timing-cta-trigger', function (e) {
            e.preventDefault();
            $('.topbar-opening-timings').toggleClass('active').slideToggle();
        });
		$(document).on('click', '.close-timings-schedule', function (e) {
            e.preventDefault();
            $('.topbar-opening-timings').toggleClass('active').slideToggle();
        });		

		$(document).on('click', '.haspopup > a', function (e) {
            e.preventDefault();
            var modellist = $(this).attr('href');
            $(modellist).modal({ 
                escapeClose: true, 
                clickClose: true, 
                showClose: true,
                fadeDuration: 100,
            });
        });

    } )( jQuery );    
} )();